import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { Wallet } from '@/features/Accounts'
import { getTeamMembers } from '@/features/Team'
import { TeamState } from '@/features/Team/types'
import { getFullName } from '@/lib/typography'
import { PaymentLimitType } from '@/types/limits'
import { Role } from '@/types/roles'

import { Eye, EyeOff } from '../icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Card,
  FormControl,
  FormField,
  FormItem,
  Input,
  MoneyInput,
  Skeleton,
} from '../ui'

import { AccountSelect } from './AccountSelect'
import { CurrencyFlag } from './CurrencyFlag'
import { LimitTypeSelect } from './LimitTypeSelect'
import { UserSelect } from './UserSelect'

type Props = {
  isPending?: boolean
  form: UseFormReturn<{
    nickname: string
    walletId: string
    requestId: string
    identityId: string
    limitAmount: string
    limitType: PaymentLimitType
    pin: { code: string; confirm: string }
  }>
  fullName?: string
  wallets?: Wallet[]
  onSelectCardholder?: (cardholder: string) => void
}

export const PhysicalCardFields = ({
  isPending,
  fullName,
  form,
  wallets,
  onSelectCardholder,
}: Props) => {
  const intl = useIntl()
  const [showPin, setShowPin] = useState(false)

  const getTeamMembersQuery = useQuery({
    queryKey: [queryKeys.getTeamMembers],
    queryFn: () => getTeamMembers(),
    select: (data) =>
      data.data
        .filter((member) => member.role !== Role.READ_ONLY)
        .filter((member) =>
          [TeamState.ACTIVE, TeamState.INVITED].includes(member.state),
        ),
    enabled: !fullName,
  })

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="identityId"
        render={({ field }) => {
          const user = getTeamMembersQuery.data?.find(
            (member) => member.id === field.value,
          )

          if (getTeamMembersQuery.isFetching || isPending) {
            return <Skeleton className="h-[54px] w-full" />
          }

          if (fullName) {
            return (
              <FormItem className="relative">
                <Input
                  className="bg-neutral-gray-200"
                  placeholder={intl.formatMessage({
                    id: 'label.cardholder',
                    defaultMessage: 'Cardholder',
                  })}
                  value={fullName}
                  disabled
                />

                <AnimatedFormLabel>
                  <FormattedMessage
                    id="label.cardholder"
                    defaultMessage="Cardholder"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )
          }

          return (
            <FormItem>
              <UserSelect
                user={user}
                value={field.value}
                users={getTeamMembersQuery.data}
                showLabel
                label={intl.formatMessage({
                  id: 'label.cardholder',
                  defaultMessage: 'Cardholder',
                })}
                onChange={(value) => {
                  const selectedUser = getTeamMembersQuery.data?.find(
                    (member) => member.identityId === value,
                  )

                  if (selectedUser) {
                    onSelectCardholder?.(getFullName(selectedUser))
                  }

                  field.onChange(value)
                }}
              />
            </FormItem>
          )
        }}
      />

      <FormField
        control={form.control}
        name="nickname"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder={intl.formatMessage({
                  id: 'card.field.nickname',
                  defaultMessage: 'Card nickname (i.e. Travel expenses)',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="card.field.nickname"
                defaultMessage="Card nickname (i.e. Travel expenses)"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="walletId"
        render={({ field }) => {
          return (
            <AccountSelect
              value={field.value}
              accounts={wallets}
              showLabel
              label={intl.formatMessage({
                id: 'card.field.sourceAccount',
                defaultMessage: 'Source account',
              })}
              onChange={field.onChange}
            />
          )
        }}
      />

      <FormField
        control={form.control}
        name="limitType"
        render={({ field }) => (
          <FormItem>
            <LimitTypeSelect value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="limitAmount"
        render={({ field }) => (
          <FormItem>
            <Card size="input" className="flex">
              <CurrencyFlag />
              <FormControl>
                <MoneyInput
                  currency={Currency.USD}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Limit amount',
                    id: 'label.limitAmount',
                  })}
                  className="text-right"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>

              <AnimatedFormLabel align="end">
                <FormattedMessage
                  defaultMessage="Limit amount"
                  id="label.limitAmount"
                />
              </AnimatedFormLabel>
            </Card>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="pin.code"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                inputMode="numeric"
                autoComplete="no-autocomplete"
                type={showPin ? 'text' : 'password'}
                maxLength={4}
                placeholder={intl.formatMessage({
                  id: 'label.4digitPin',
                  defaultMessage: '4-digit PIN',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.4digitPin"
                defaultMessage="4-digit PIN"
              />
            </AnimatedFormLabel>

            <Button
              variant="ghost"
              size="inline"
              onClick={() => setShowPin((prev) => !prev)}
              type="button"
              className="absolute right-4 top-5"
              aria-label={
                showPin
                  ? intl.formatMessage({
                      defaultMessage: 'Show PIN',
                      id: 'action.showPin',
                    })
                  : intl.formatMessage({
                      defaultMessage: 'Hide PIN',
                      id: 'action.hidePin',
                    })
              }
            >
              {showPin ? (
                <Eye className="size-4 text-neutral-gray-400" />
              ) : (
                <EyeOff className="size-4 text-neutral-gray-400" />
              )}
            </Button>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="pin.confirm"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                inputMode="numeric"
                autoComplete="no-autocomplete"
                type={showPin ? 'text' : 'password'}
                maxLength={4}
                placeholder={intl.formatMessage({
                  id: 'label.4digitPinConfirm',
                  defaultMessage: 'Confirm 4-digit PIN',
                })}
                {...field}
              />
            </FormControl>

            <AnimatedFormLabel>
              <FormattedMessage
                id="label.4digitPinConfirm"
                defaultMessage="Confirm 4-digit PIN"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />
    </div>
  )
}
