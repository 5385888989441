import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { format, isEqual, isValid, parse } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { taxIdByCountry } from '@/constants/countries'
import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { parseInputDate } from '@/lib/date'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import { CountrySelect, Widget } from '@/shared/components'
import { Calendar as CalendarIcon } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryTaxId } from '@/types/country'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { OnboardingDocumentField } from '../components/OnboardingDocumentField'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  CompanyFormationStep as CompanyFormationStepType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../types'

function parseTaxIdToDate(taxId: string) {
  const RFCExtractedDate = taxId.slice(3, 9)
  const [year, month, day] = RFCExtractedDate.match(/.{2}/g) ?? []

  const utcDate = new Date().toUTCString()

  const RFCDate = parse(`${day}/${month}/${year}`, 'dd/MM/yy', utcDate)

  return RFCDate
}

const companyFormationStepSchema = z
  .object({
    COUNTRY_OF_INCORPORATION: z.string(),
    TAX_ID: z.string(),
    TAX_ID_TYPE: z.string(),
    DATE_OF_INCORPORATION: z.string(),
    FORMATION_DOCUMENTS: z.array(z.any()),
    TAX_CERTIFICATE_OF_GOOD_STANDING: z.array(z.any()),
  })
  .refine(
    (data) => {
      const { DATE_OF_INCORPORATION, TAX_ID, TAX_ID_TYPE } = data

      switch (TAX_ID_TYPE) {
        case CountryTaxId.MX: {
          const RFCDate = parseTaxIdToDate(TAX_ID)

          const dateOfIncorporation = parse(
            DATE_OF_INCORPORATION,
            'dd/MM/yyyy',
            new Date(),
          )

          return isEqual(RFCDate, dateOfIncorporation)
        }

        default:
          return true
      }
    },
    {
      message: 'validation.dateOfIncorporation.notMatchingRFC',
      path: ['DATE_OF_INCORPORATION'],
    },
  )
  .refine(
    (data) => {
      const { DATE_OF_INCORPORATION, TAX_ID_TYPE } = data

      switch (TAX_ID_TYPE) {
        case CountryTaxId.MX: {
          const dateOfIncorporation = parse(
            DATE_OF_INCORPORATION,
            'dd/MM/yyyy',
            new Date(),
          )

          const isDateInTheFuture = dateOfIncorporation > new Date()

          return !isDateInTheFuture
        }

        default:
          return true
      }
    },
    {
      message: 'validation.dateOfIncorporation.invalidDate',
      path: ['DATE_OF_INCORPORATION'],
    },
  )

type CompanyFormationStepSchema = z.infer<typeof companyFormationStepSchema>

const DEFAULT_VALUES: CompanyFormationStepSchema = {
  COUNTRY_OF_INCORPORATION: '',
  TAX_ID: '',
  TAX_ID_TYPE: '',
  DATE_OF_INCORPORATION: '',

  FORMATION_DOCUMENTS: [new File([], '')],
  TAX_CERTIFICATE_OF_GOOD_STANDING: [new File([], '')],
}

const COMPANY_FORMATION_FORM_ID = 'company-formation-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const CompanyFormationStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const [month, setMonth] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const [openCalendar, setOpenCalendar] = useState(false)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const form = useForm<CompanyFormationStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(companyFormationStepSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,
    enabled: !!config?.name,
  })

  const onSubmit: SubmitHandler<CompanyFormationStepSchema> = useCallback(
    async (data) => {
      const documents = stepDetailsQuery.data?.stepDetails.documents ?? []

      if (!config?.name) {
        return
      }

      const {
        COUNTRY_OF_INCORPORATION,
        DATE_OF_INCORPORATION,
        TAX_ID,
        TAX_ID_TYPE,
      } = data

      const yearPart = DATE_OF_INCORPORATION.split('/')[2]

      const fullYear = yearPart.length === 2 ? `20${yearPart}` : yearPart

      const dateOfIncorporationWithReplacedYear = DATE_OF_INCORPORATION.replace(
        yearPart,
        fullYear,
      )

      const timezoneOffset = new Date().getTimezoneOffset()

      const localTime = new Date(
        new Date().getTime() - timezoneOffset * 60 * 1000,
      )

      try {
        await mutateAsync({
          stepName: OnboardingStepName.COMPANY_FORMATION,
          stepDetails: {
            countryOfIncorporation: COUNTRY_OF_INCORPORATION,
            taxId: TAX_ID,
            taxIdType: TAX_ID_TYPE,

            dateOfIncorporation: format(
              parse(
                dateOfIncorporationWithReplacedYear,
                'dd/MM/yyyy',
                localTime,
              ),
              'yyyy-MM-dd',
            ),
            documents,
          },
        })

        const currentStepIndex = steps.findIndex(
          (step) => step.name === config.name,
        )

        const nextStep = steps[currentStepIndex + 1]

        await queryClient.refetchQueries({
          queryKey: [queryKeys.getOnboardingState],
        })

        queryClient.invalidateQueries({
          queryKey: [queryKeys.getOnboardingStepDetails, config.name],
        })

        navigate(generatePath(ONBOARDING_STEP, { step: nextStep.name }))
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [
      config?.name,
      navigate,
      notifyError,
      stepDetailsQuery.data?.stepDetails.documents,
      steps,
      mutateAsync,
    ],
  )

  const fields = form.watch()

  useEffect(() => {
    const hasExistingFields = [
      fields.COUNTRY_OF_INCORPORATION,
      fields.TAX_ID,
      fields.TAX_ID_TYPE,
      fields.DATE_OF_INCORPORATION,
    ].some(Boolean)

    if (hasExistingFields) {
      return
    }

    if (stepDetailsQuery.data) {
      const { dateOfIncorporation, countryOfIncorporation, taxId, taxIdType } =
        stepDetailsQuery.data
          .stepDetails as CompanyFormationStepType['stepDetails']

      const responseFields = [countryOfIncorporation, taxId, taxIdType]

      const formFields = [
        'COUNTRY_OF_INCORPORATION',
        'TAX_ID',
        'TAX_ID_TYPE',
      ] as const

      if (dateOfIncorporation) {
        const formattedDate = format(
          parse(dateOfIncorporation, 'yyyy-MM-dd', new Date().toUTCString()),
          'dd/MM/yyyy',
        )

        const parsedDate = parse(dateOfIncorporation, 'yyyy-MM-dd', new Date())

        if (isValid(parsedDate)) {
          setSelectedDate(parsedDate)
          setMonth(parsedDate)
        }

        form.setValue('DATE_OF_INCORPORATION', formattedDate)
      }

      if (!dateOfIncorporation && taxId && taxIdType === CountryTaxId.MX) {
        const RFCDate = parseTaxIdToDate(taxId)

        if (isValid(RFCDate)) {
          setSelectedDate(RFCDate)
          setMonth(RFCDate)
        }

        form.setValue('DATE_OF_INCORPORATION', format(RFCDate, 'dd/MM/yyyy'))
      }

      formFields.forEach((field, index) => {
        if (!responseFields[index]) {
          return
        }
        form.setValue(field, responseFields[index] ?? '')
      })
    }
  }, [
    fields.COUNTRY_OF_INCORPORATION,
    fields.DATE_OF_INCORPORATION,
    fields.TAX_ID,
    fields.TAX_ID_TYPE,
    form,
    stepDetailsQuery.data,
  ])

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails.documents],
  )

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredFields = config.fields.filter((field) => field.required)
    const requiredDocuments = config.documents.filter((doc) => doc.required)

    return (
      requiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) && requiredDocuments.every((doc) => getFilesByField(doc.type).length)
    )
  }, [config?.documents, config?.fields, fields, getFilesByField])

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <SlideInScreen>
      <Typography variant="h3" text="center">
        <FormattedMessage
          defaultMessage="Company formation"
          id="onboarding.companyFormation.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Submit the documents and get the company set up!"
          id="onboarding.companyFormation.subtitle"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={COMPANY_FORMATION_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Widget
            title={
              <FormattedMessage
                defaultMessage="Formation details"
                id="onboarding.companyFormation.formationDetails"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="COUNTRY_OF_INCORPORATION"
              render={({ field }) => (
                <FormItem>
                  <CountrySelect
                    onChange={(value) => {
                      field.onChange(value)
                      form.setValue('TAX_ID_TYPE', taxIdByCountry[value])

                      if (form.getValues('DATE_OF_INCORPORATION') !== '') {
                        form.trigger('DATE_OF_INCORPORATION')
                      }
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="TAX_ID"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.taxId',
                        defaultMessage: 'Tax ID',
                      })}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value)

                        if (form.getValues('DATE_OF_INCORPORATION') !== '') {
                          form.trigger('DATE_OF_INCORPORATION')
                        }
                      }}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="{taxIdType, select, CUIT {CUIT} EIN {EIN} NIT {NIT (include verification digit)} RFC {RFC} RUT_CHL {RUT} RUT_URY {RUT} CNPJ {CNPJ} NIP_ESP {NIP} NIPC {NIPC} other {Tax ID}}"
                      id="auth.taxIdType"
                      values={{ taxIdType: form.getValues('TAX_ID_TYPE') }}
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="DATE_OF_INCORPORATION"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      accept="[0-9\/]+"
                      value={field.value}
                      placeholder={intl.formatMessage({
                        id: 'onboarding.field.dateOfIncorporation',
                        defaultMessage: 'Date of incorporation (DD/MM/YYYY)',
                      })}
                      onChange={(e) => {
                        field.onChange(parseInputDate(e.target.value))

                        const parsedDate = parse(
                          e.target.value,
                          'dd/MM/yyyy',
                          new Date(),
                        )

                        if (isValid(parsedDate)) {
                          setSelectedDate(parsedDate)
                          setMonth(parsedDate)
                        } else {
                          setSelectedDate(undefined)
                        }
                      }}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="onboarding.field.dateOfIncorporation"
                      defaultMessage="Date of incorporation (DD/MM/YYYY)"
                    />
                  </AnimatedFormLabel>

                  <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
                    <PopoverTrigger asChild>
                      <Button
                        size="inline"
                        variant="ghost"
                        className={cn(
                          'absolute right-[18px] top-[18px] text-left font-normal',
                        )}
                      >
                        <CalendarIcon className="size-4" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={selectedDate}
                        onMonthChange={setMonth}
                        month={month}
                        onSelect={(value) => {
                          if (!value) {
                            return
                          }

                          const parsedDate = format(value, 'dd/MM/yyyy')

                          field.onChange(parsedDate)

                          setSelectedDate(value)
                          setOpenCalendar(false)
                        }}
                        disabled={(date) =>
                          date > new Date() || date < new Date('1900-01-01')
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                defaultMessage="Supporting documentation"
                id="onboarding.companyFormation.supportingDocuments"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="FORMATION_DOCUMENTS"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OnboardingDocumentField
                      isLoadingFiles={stepDetailsQuery.isPending}
                      title={intl.formatMessage({
                        id: 'onboarding.companyFormation.formationDocuments',
                        defaultMessage: 'Formation documents',
                      })}
                      description={intl.formatMessage({
                        id: 'onboarding.companyFormation.formationDocuments.description',
                        defaultMessage: `Submit your company's (i) certificate of incorporation, (ii) bylaws and (iii) the certificate of registry with the appropriate authority. You may upload more than one document`,
                      })}
                      step={config?.name ?? ''}
                      files={getFilesByField(field.name)}
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="TAX_CERTIFICATE_OF_GOOD_STANDING"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OnboardingDocumentField
                      isLoadingFiles={stepDetailsQuery.isPending}
                      title={intl.formatMessage({
                        id: 'onboarding.step.companyFormation.taxRegistryCertificate',
                        defaultMessage: 'Tax registration certificate',
                      })}
                      description={intl.formatMessage({
                        id: 'onboarding.companyFormation.taxCertificateOfGoodStanding.description',
                        defaultMessage:
                          'Provide the certificate of issuance of the tax identification number and a certificate of good standing upon the relevant tax authority, no older than 3 months',
                      })}
                      step={config?.name ?? ''}
                      files={getFilesByField(field.name)}
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          disabled={
            !hasAllRequiredFields ||
            isPending ||
            isSuccess ||
            !!form.formState.errors.DATE_OF_INCORPORATION
          }
          loading={isPending || isSuccess}
          form={COMPANY_FORMATION_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
